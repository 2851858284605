'use client'

import {useState} from "react";
import Link from "next/link";
import {SiteSettings, Tag} from "@/types/sanity";
import CompanyLogo from "@/components/company-logo";
import {Cross1Icon, HamburgerMenuIcon} from "@radix-ui/react-icons";
import Image from "next/image";

export default function MainNavigation({tags, siteSettings, blogCounts}: {
    siteSettings: SiteSettings,
    tags: Tag[],
    blogCounts: { interview: number, article: number, qaPost: number, newsPost: number, deal: number }
}) {
    const [openMenu, setOpenMenu] = useState(false);

    const handleClick = () => {
        if (openMenu) {
            setOpenMenu(false);
        } else {
            setOpenMenu(true);
        }
    }

    return (
        <>
            {siteSettings && <nav id="main-nav" className="bg-base w-full flex flex-wrap items-center md:p-6"
                                  aria-label="Main">
                <div className="flex items-center w-full md:w-auto p-6 md:p-0">
                    <Link href={'/'}>
                        {!siteSettings.logo && <CompanyLogo companyName={siteSettings.siteName}/>}
                        {!!siteSettings.logo &&
                            <Image quality={100} src={siteSettings.logo} alt={siteSettings.siteName} width={174} height={57}
                                   className={'object-cover'} priority={true}/>
                        }
                    </Link>

                    <div className="flex justify-end w-full md:hidden">
                        <button onClick={handleClick} className="text-white md:text-black" aria-label="Open main menu"
                                aria-expanded={openMenu}>
                            {openMenu ?
                                <Cross1Icon className="h-6 w-6"/> :
                                <HamburgerMenuIcon className="h-6 w-6"/>}
                        </button>
                    </div>
                </div>
                <div
                    className={`${openMenu ? 'max-h-[100vh]' : 'max-h-[0px] md:max-h-none'} duration-300 transition-all overflow-hidden md:overflow-visible shadow-xl md:shadow-none w-full md:w-auto md:ml-auto divide-y md:divide-y-0 md:flex md:justify-end`}>
                    <ul className={"flex flex-col md:flex-row items-center gap-6 py-5 md:py-0 [&__a]:transition [&__a]:text-primary hover:[&__a]:text-secondary hover:[&__a]:border-b-2 hover:[&__a]:after:border-b-secondary hover:[&__a]:after:border-b focus-visible:[&__a]:text-secondary "}>
                        {tags && tags?.map((tag, index) => (
                            <li key={index}>
                                <Link href={`/tag/${tag.slug}`}>{tag.name}</Link>
                            </li>
                        ))}

                        {blogCounts.qaPost > 0 && <li>
                            <Link href={`/qa`}>Q&A Posts</Link>
                        </li>}

                        {blogCounts.article > 0 && <li>
                            <Link href={`/insight`}>Articles</Link>
                        </li>}

                        {blogCounts.interview > 0 && <li>
                            <Link href={`/interview`}>Interviews</Link>
                        </li>}

                        {blogCounts.newsPost > 0 && <li>
                            <Link href={`/news`}>News</Link>
                        </li>}

                        {blogCounts.deal > 0 && <li>
                            <Link href={`/deals`}>Deals</Link>
                        </li>}
                    </ul>
                </div>
            </nav>}
        </>
    )

}