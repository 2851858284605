import {ReactNode} from "react";

export default function CompanyLogo({
                                        companyName,
                                    }: { companyName: ReactNode | string }) {


    return (

        <div className={'flex flex-row items-center w-full'}>
            <svg
                viewBox={`0 20 150 60`}
                role="img"
            width={60}
            >
                <title>Company logo</title>
                <g>
                    <path
                        d="M43.48 24.24C16.12 21.89 0 42.64 0 56.9c0 12 9.73 21.73 21.73 21.73S43.46 68.9 43.46 56.9c0-10.7-7.74-19.6-17.93-21.4h-.01c3.59-6.37 10.24-10.78 17.96-11.22v-.05ZM97.86 24.24C70.51 21.89 54.38 42.63 54.38 56.9c0 12 9.73 21.73 21.73 21.73S97.84 68.9 97.84 56.9c0-10.7-7.74-19.6-17.93-21.4h-.01c3.59-6.37 10.24-10.78 17.96-11.22v-.05ZM122.89 0h4.49v102.69h-4.49z"
                        className={'fill-primary'}
                    />
                </g>
            </svg>
            <div className={'text-white block whitespace-nowrap font-header'}>{companyName}</div>
        </div>
    )
}
